<template>
  <main>
    <router-view />
  </main>
</template>

<script setup>
import Collect from './views/collect/index.vue'
window.addEventListener("orientationchange", () => {
  if (resizeTimeput) {
    window.clearTimeout(resizeTimeput)
  }
  resizeTimeput = window.setTimeout(() => {
    store.dispatch("resize")
    resizeTimeput = null
  }, 100)
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
