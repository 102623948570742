import axios from "axios"

const localTestServer = "http://crealportal.local/"
const remoteTestServer = "https://creal-portal-debug.configreality.com/"
const remoteServer = "https://creal-portal.configreality.com/"

const myServers = [localTestServer, remoteTestServer, remoteServer]
let server
// const { MODE } = import.meta.env
const MODE = "development"
if (MODE === "development") {
  server = localTestServer // 测试
  server = remoteTestServer
} else if (MODE === "trial") {
  console.log("体验版")
  // 正式
  server = remoteTestServer
} else if (MODE === "production") {
  console.log("正式版")
  // 正式
  server = remoteServer
}

const is_test = (() => {
  return server == localTestServer
})()

const baseURL = server + "api/"
axios.defaults.baseURL = baseURL

const getTokenStorage = () => {
  return localStorage.getItem("token")
}

axios.interceptors.request.use(
  (config) => {
    /* 请求之前拦截器。可以使用async await 做异步操作 */
    //   uni.showLoading({
    //     title: '加载中',
    //     mask: true,
    //   })
    const { baseURL, fullPath, url, data } = config
    //在我方服务器才需要设置token 去除/api后进行比较
    if (myServers.includes(baseURL.slice(0, -4)) && url.indexOf("https://") != 0) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + getTokenStorage(),
        Accept: "application/json",
      }
    }
    return config
  },
  (config) => {
    return Promise.reject(config)
  }
)

axios.interceptors.response.use(
  async (response) => {
    /* 请求之后拦截器。可以使用async await 做异步操作  */
    // uni.hideLoading()
    console.log(response)
    if ([200, 201].includes(response.status)) {
      return response.data
    } else {
      // window.alert(response.data.message || '请求错误');
      return Promise.reject(response.data)
    }
  },
  (response) => {
    // console.log(response)
    // 请求错误做点什么。可以使用async await 做异步操作
    // uni.hideLoading()
    // console.log('reject', response)
    // if (response.statusCode == 401) {
    //   uni.showToast({
    //     title: '登陆超时，请重新登陆',
    //     icon: 'none',
    //     duration: 1500,
    //   })
    //   // store.dispatch('logout')
    //   // uni.removeStorageSync('Phone');
    //   store.commit('$user/logout')
    //   store.dispatch('$user/code2OpenId')
    //   const timer = setTimeout(() => {
    //     clearTimeout(timer)
    //     uni.reLaunch({
    //       url: '/pages/auth/login',
    //     })
    //   }, 1500)
    //   return Promise.reject(response)
    // }
    // if (response.statusCode == 422) {
    //   console.log(response)
    //   let key = Object.keys(response.data.errors)[0]
    //   let tip = response.data.errors[key][0]
    //   uni.showToast({
    //     title: tip,
    //     icon: 'none',
    //     duration: 1500,
    //   })
    //   return Promise.reject(response)
    // }
    // console.log('statusCode', statusCode)
    // return Promise.reject(response)
  }
)
export { axios, server, baseURL, is_test }
