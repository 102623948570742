<script setup>
  import { reactive, onMounted } from "vue"
  import { useRouter } from "vue-router"
  import request from "../../utils/request"
  import Reel from '../../components/reel.vue'
  
  const router = useRouter();

  const collect = reactive({
    currentIndex: 0,
    libraries:[]
  })
  

  const onEnter = (e) => {
      router.push({ name: "collect_detail" })
  }

  const onChoose = (index) => {
    if(index === -1) {
      return;
    }
    collect.currentIndex = index;
  }

  onMounted(()=>{
    const id = router.currentRoute.value.params.id;
    request.getLibraryTypesById(id).then((data) => {
      collect.libraries = data.data.libraries
    });
  });

  const id = localStorage.getItem("id");
</script>

<template>
    <div class="wrapper">
        <div class="reel">
          <reel :value="collect.libraries" @onChoose="onChoose"/>
        </div>
        <div class="header">
            <span class="title">光跃平台</span>
            <button class="play" />
        </div>
        <div class="content">
            <div class="main">
              <img :src="collect.libraries[collect.currentIndex]?.box_picture" />
            </div>
            <div class="operate">
              <div class="operate_info">
                <span class="title">{{collect.libraries[collect.currentIndex]?.name}}</span>
                <div class="user">
                  <img class="user_avatar" :src="collect.libraries[collect.currentIndex]?.author_avatar"/>
                  <span class="user_name">{{collect.libraries[collect.currentIndex]?.author_name}}</span>
                </div>
              </div>
            </div>
            <button class="more" @click="onEnter">查看更多</button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
  @import "assets/styles/mobile_base.scss";

  .wrapper {
      width: 100%;
      min-height: 100vh;
      background: linear-gradient(180.00deg, rgba(0, 0, 0, 0) 30%, #999 100%);
      position: relative;

      .header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: px2rem(28);
        margin-bottom: px2rem(10);
        z-index: 999999;

        .title{
          font-size: px2rem(28);
          margin-left: px2rem(4);
          font-weight: bold;
        }

        .play {
          margin-right: px2rem(1);
          width: px2rem(40);
          height: px2rem(40);
          background: #000;
          border-radius: 100%;
          color: #fff;
          text-align: center;
        }
      }

      .content {
        position: relative;
        width: px2rem(264.5);
        margin: auto;
        text-align: center;
        z-index: 999999;

        .main {
          display: inline-block;
          width: px2rem(223.49);
          height: px2rem(329.78);
          background-color: #fff;
          border-radius: px2rem(16);
          margin-bottom: px2rem(23.73);
          box-shadow: 33px 32px 40px rgba(0, 0, 0, 0.16);

          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: px2rem(16);
            border: none;
          }
        }

        .operate {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: px2rem(172);

          &_info {
            margin: auto;
            text-align: center;
            
            .title {
                display: block;
                font-family: "Alibaba PuHuiTi Medium";
                font-weight: bold;
                font-size: px2rem(24);
                margin-bottom: px2rem(6.04);
            }

            .user {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: auto;
              
              &_avatar {
                width: px2rem(20);
                height: px2rem(20);
                border-radius: 100%;
                margin-right: px2rem(12.81);
              }

              &_name {
                font-family: "Alibaba PuHuiTi 2.0 55 Regular";
                font-size: px2rem(15);
                text-align: left;
              }
            }
          }
        }

        .more {
          position: fixed;
          display: block;
          bottom: px2rem(48);
          left: 50%;
          margin-left: px2rem(-51);;
          width: px2rem(102);
          height: px2rem(30);
          border-radius: px2rem(14);
          background: #000;
          color: #fff;
        }
      }

      .reel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
      }
  }
  
</style>
