import { axios } from "./http"

const getLibraryTypes = () => {
  return axios.get(`https://qianxicity.linkerlab.net/api/libraryTypes`)
}

const getLibraryTypesById = (id) => {
  return axios.get(`https://qianxicity.linkerlab.net/api/libraryTypes/${id}`)
}

export default {
  getLibraryTypes,
  getLibraryTypesById
}
